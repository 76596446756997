export const LOGIN_ROUTE = "/login";
export const SINGUP_ROUTE = "/signup";
export const ACCOUNT_ACTIVATED_ROUTE = "/activate/:userId";
export const FORGOT_PASS_ROUTE = "/forgotpass";
export const RESET_PASS_ROUTE = "/reset/:userId";
export const PROFILE_ROUTE = "/user/profile";
export const STUDENT_BOARD_ROUTE = "/st/board";
export const STUDENT_ASSIGNMENTS_ROUTE = "/assigments";
export const STUDENT_ASSIGNMENT_GROUPS_ROUTE = "/assigment/groups";
export const INSTRUCTOR_INSTITUTION_LIST_ROUTE = "/institution/list";
export const INSTRUCTOR_COURSE_LIST_ROUTE = "/course/list";
export const INSTRUCTOR_INSTITUTION_UPSERT_ROUTE = "/institution/upsert";
export const CASE_STUDY_LIST = "/casestudy/list";
export const CASE_STUDY_ASSIGNMENTS = "/casestudy/assignments";
export const CASE_STUDY_ASSIGNMENT_GROUPS = "/casestudy/assignment/groups";
export const CASE_STUDY_PURCHASE_ROUTE = "/casestudy/purchase";
export const CASE_STUDY_PURCHASE_CHECKOUT_ROUTE =
  "/casestudy/purchase/checkout";
export const CASE_STUDY_PURCHASE_COMPLETION_ROUTE =
  "/casestudy/purchase/completion";
export const USER_INSTITUTION_ACCOUNT_VERIFIED_ROUTE =
  "/institution/verify/:id";
export const CASE_STUDY_ASSIGNMENT_ANSWER = "/casestudy/assignment/answer";
export const CASE_STUDY_ASSIGNMENT_ANSWER_INDIVIDUAL = "/casestudy/assignment/answer/ind";
