import React from "react";
import { Container, Paper } from "@mui/material";
import { STUDENT_ASSIGNMENTS_ROUTE } from "../../helper/Constants";
import PageHeader from "../../components/PageHeader";
import { useNavigate, useLocation } from "react-router-dom";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paymentDetails: {
    padding: "20px",
    border: "1px solid #ddd",
    marginBottom: "20px",
  },
  printButton: {
    padding: "10px 20px",
    margin: "2px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
    // Hide button during print
    "@media print": {
      display: "none",
    },
  },
  backButton: {
    padding: "10px 20px",
    margin: "2px",
    backgroundColor: "#04AA6D",
    color: "white",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
    // Hide button during print
    "@media print": {
      display: "none",
    },
  },
  // Styles for the printed content
  printContent: {
    "@media print": {
      fontSize: "12px",
      color: "#000",
      body: {
        margin: 0,
        padding: 0,
        overflow: "visible",
        height: "auto",
        maxHeight: "100%",
      },
      html: {
        margin: 0,
        padding: 0,
        overflow: "visible",
        height: "auto",
        maxHeight: "100%",
      },
      "*": {
        margin: 0,
        padding: 0,
        boxSizing: "border-box",
        overflow: "visible",
      },
    },
  },
}));

const Completion = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const paymentDetails = state.paymentIntent;

  const handlePrint = () => {
    window.print();
  };

  // const handleCopy = () => {
  //   // Copy the reference number to the clipboard
  //   navigator.clipboard.writeText(clientSecret);
  // };

  // useEffect(() => {
  //   const paymentIntentClientSecret = new URLSearchParams(
  //     window.location.search
  //   ).get("payment_intent_client_secret");

  //   if (!paymentIntentClientSecret) {
  //     return;
  //   } else setClientSecret(paymentIntentClientSecret);
  // }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title={"Payment Successful!"} />

      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <div>
          <h2>Thank you for your purchase!</h2>
          <div className={`${classes.paymentDetails} ${classes.printContent}`}>
            <p>
              <strong>Payment ID:</strong> {paymentDetails.id}
            </p>
            <p>
              <strong>Amount:</strong> $
              {(paymentDetails.amount / 100).toFixed(2)}
            </p>
            <p>
              <strong>Payment Method:</strong> {paymentDetails.payment_method}
            </p>
          </div>

          <button className={classes.printButton} onClick={handlePrint}>
            Print Payment Details
          </button>
          <br />
          <button
            className={classes.backButton}
            onClick={() => navigate(STUDENT_ASSIGNMENTS_ROUTE)}
          >
            Back to Home page
          </button>
        </div>
      </Paper>
      {/* <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Thank you for your purchase!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Reference Number
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {paymentDetails.id}
              <IconButton color="info" onClick={handleCopy}>
                <FileCopyIcon />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>
      </Paper> */}
      {/* <Grid item xs={4} sm={8} md={8} sx={{ mt: 2 }} align="center">
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction={{ xs: "column", sm: "row" }}
          sx={{ ml: 1 }}
        >
          <MuiButton
            classes={classes.printButton}
            text="Print"
            color="success"
            onClick={handlePrint}
          />

          <MuiButton
            text="Back to Home page"
            color="info"
            variant="outlined"
            onClick={() => navigate(STUDENT_ASSIGNMENTS_ROUTE)}
          />
        </Stack>
      </Grid> */}
    </Container>
  );
};

export default Completion;
