import { parse, isBefore, isEqual } from "date-fns";

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const isCurrentDateBefore = (dueDateString) => {
  // Append the time "11:59 PM" to the dueDate string and parse it
  const dueDateWithTime = `${dueDateString} 11:59 PM`;

  // Parse the dueDate with time (e.g., "10 Oct 2040 11:59 PM")
  const dueDate = parse(dueDateWithTime, "dd MMM yyyy hh:mm a", new Date());

  // Get the current date
  const currentDate = new Date();

  // Check if the current date is before or equal to the due date
  return isBefore(currentDate, dueDate) || isEqual(currentDate, dueDate);
};
