import React from "react";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useNavigate } from "react-router-dom";
import { upperCaseFirstLetter } from "../../../helper/TextHelper";
import {
  Avatar,
  Box,
  CardContent,
  Grid,
  Card,
  CardActions,
  Typography,
  Tooltip,
} from "@mui/material";
import { MuiButton } from "../../../components";
import IconWithText from "../../../components/IconWithText";
import {
  CASE_STUDY_ASSIGNMENT_ANSWER_INDIVIDUAL,
  CASE_STUDY_PURCHASE_ROUTE,
} from "../../../helper/Constants";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import TopicIcon from "@mui/icons-material/Topic";
import { isCurrentDateBefore } from "../../../helper/DateHelper";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function IndividualAssignmentCard({
  assignment,
  handleDelete,
  handleDownload,
  handleGuideline,
}) {
  const navigate = useNavigate();

  const getStatusColor = (status) => {
    switch (status) {
      case "UNKNOWN":
        return "#ff9800"; // Orange '#4caf50'; // Green '#f44336'; // Red  '#2196f3'; // Blue
      default:
        return "#4caf50";
    }
  };

  function formatString(str) {
    // Split the string by underscores
    let words = str.split("_");

    // Capitalize the first letter of each word and make the rest lowercase
    for (let i = 0; i < words.length; i++) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the words with spaces
    return words.join(" ");
  }

  const goForPayment = (caseStudyId, assignment) => {
    navigate(CASE_STUDY_PURCHASE_ROUTE, {
      state: { caseStudyId, assignment },
    });
  };

  const handleAnswer = () => {
    navigate(CASE_STUDY_ASSIGNMENT_ANSWER_INDIVIDUAL, {
      state: {
        // when individual submit an answer, a group will be created automatically in the backend
        groupId: assignment.individualGroupId,
        assignmentId: assignment.id,
        caseStudyId: assignment.caseStudyId,
      },
    });
  };

  // const handlePdfDownload = async () => {
  //   const fileUrl =
  //     "https://res.cloudinary.com/dzqwwpip5/image/upload/v1727803399/bizcaselab-assets/uuvxtftwhh2ypvuqmdor.pdf";
  //   try {
  //     const response = await fetch(fileUrl);
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const blob = await response.blob(); // Convert the response to a Blob
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob); // Create a URL for the Blob
  //     link.setAttribute("download", `Case_study_guideline.pdf`); // Setting the file name for download
  //     document.body.appendChild(link);
  //     link.click(); // Trigger the download
  //     document.body.removeChild(link); // Clean up
  //   } catch (error) {
  //     console.error("There was an error downloading the file:", error);
  //   }
  // };

  const renderAnswerBtn = () => {
    if (assignment.individualGroupId) {
      return (
        <MuiButton
          size="small"
          text={"View Answer"}
          variant="text"
          sx={{ ml: 1 }}
          onClick={handleAnswer}
        />
      );
    } else if (isCurrentDateBefore(assignment.dueDate)) {
      return (
        <MuiButton
          size="small"
          text={"Submit Answer"}
          variant="text"
          sx={{ ml: 1 }}
          onClick={handleAnswer}
        />
      );
    } else {
      return (
        <MuiButton
          size="small"
          text={"Submission Closed"}
          variant="text"
          sx={{ ml: 1 }}
          disabled={true}
        />
      );
    }
  };

  const renderActions = (paymentStatus) => {
    switch (paymentStatus) {
      case "PAID":
        return (
          <CardActions
            disableSpacing
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>{renderAnswerBtn()}</div>
            <div style={{ marginRight: "16px" }}>
              <Tooltip title={"Case Study"}>
                <IconButton
                  color="success"
                  onClick={() => handleDownload(assignment.title)}
                >
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Guideline"}>
                <IconButton color="success" onClick={() => handleGuideline()}>
                  <MenuBookIcon />
                </IconButton>
              </Tooltip>
            </div>
          </CardActions>
        );
      case "UNKNOWN":
        return (
          <CardActions>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <MuiButton
                  variant="text"
                  size="small"
                  text="UnLock"
                  onClick={() =>
                    goForPayment(assignment.caseStudyId, assignment)
                  }
                />
              </Box>
            </Grid>
          </CardActions>
        );

      default:
        return null;
    }
  };

  return (
    <Card
      elevation={5}
      sx={{
        m: 0.5,
        border: "1px solid",
        borderColor: getStatusColor(assignment.paymentStatus),
      }}
    >
      <CardHeader
        avatar={
          assignment.paymentStatus !== "UNKNOWN" ? (
            <Avatar sx={{ bgcolor: "#008000" }}>
              <PersonIcon />
            </Avatar>
          ) : (
            <Avatar sx={{ bgcolor: "#f44336" }}>
              <LockIcon />
            </Avatar>
          )
        }
        action={
          <IconButton color="secondary" onClick={handleDelete}>
            <DeleteOutlinedIcon />
          </IconButton>
        }
        title={upperCaseFirstLetter(assignment.title)}
        subheader={
          <Box>
            <Typography variant="body2" color="textSecondary" component="div">
              Due date: {assignment.dueDate}
            </Typography>
            <Typography variant="caption" color="warning">
              <AccessTimeIcon
                color="warning"
                fontSize="small"
                style={{ verticalAlign: "middle", marginRight: 4 }}
              />
              11:59 PM
            </Typography>
          </Box>
        }
      />
      <CardContent sx={{ m: 1 }}>
        <>
          <IconWithText
            text={upperCaseFirstLetter(assignment.caseStudyTitle)}
            sx={{ mb: 1 }}
          />
          <IconWithText
            icon={<MenuBookIcon />}
            text={upperCaseFirstLetter(assignment.course)}
          />
          <IconWithText
            icon={<CalendarMonthIcon />}
            text={formatString(assignment.semester)}
          />
          <IconWithText
            icon={<TopicIcon />}
            text={formatString(assignment.concept)}
          />
        </>
      </CardContent>
      {renderActions(assignment.paymentStatus)}
    </Card>
  );
}
