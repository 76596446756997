import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import { CASE_STUDY_PURCHASE_COMPLETION_ROUTE } from "../../helper/Constants";
import { useNavigate } from "react-router-dom";
import { MuiButton } from "../../components";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { clientSecret, amount, currency } = props;
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          // setMessage("Your payment was not successful, please try again.");
          setMessage("Please fill up the form to make a payment.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url:
        // window.location.origin + CASE_STUDY_PURCHASE_COMPLETION_ROUTE,
      },
      redirect: "if_required", // Only redirect if required (e.g., for 3D Secure)
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      console.error("Payment failed:", error.message);
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      // Navigate without page reload
      navigate(CASE_STUDY_PURCHASE_COMPLETION_ROUTE, {
        state: { paymentIntent },
      });
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <LoadingButton
        sx={{ mt: 2 }}
        fullWidth
        disabled={isLoading || !stripe || !elements}
        type="submit"
        // color="#5469d4"
        variant="contained"
        loading={isLoading}
      >
        {"Pay " + currency + " " + amount}
      </LoadingButton>
      <MuiButton
        sx={{ mt: 2 }}
        fullWidth
        text="Cancel"
        color="info"
        variant="outlined"
        onClick={() => navigate(-1)}
      />
      {/* Show any error or success messages */}
      {message && (
        <Typography
          id="payment-message"
          variant="body1"
          sx={{
            color: "rgb(105, 115, 134)",
            fontSize: "16px",
            lineHeight: "20px",
            paddingTop: "12px",
            textAlign: "center",
          }}
        >
          {message}
        </Typography>
      )}
    </form>
  );
}
