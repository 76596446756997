import React from "react";
import { makeStyles } from "@mui/styles";
import ImageCard from "./ImageCard";
import cards from "./cards";
import useWindowPosition from "../../../hook/useWindowPosition";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "75vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

export default function ActionCards() {
  const classes = useStyles();
  const checked = useWindowPosition("header");
  return (
    <div className={classes.root} id="action-cards">
      {cards.map((place, index) => (
        <ImageCard key={index} place={place} checked={checked} />
      ))}
    </div>
  );
}
