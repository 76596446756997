import { STUDENT_BOARD_ROUTE } from "../../../helper/Constants";

const cards = [
  {
    title: "Get started",
    description: "Are you a student and have a case study code?",
    imageUrl: "/resources/landing.jpg",
    route: STUDENT_BOARD_ROUTE,
    time: 1500,
    buttonText: "Let’s Go",
    buttonVariant: "contained",
  },
];

export default cards;
