import {httpClient} from "./RestService";

export const login = async (data) => {
    return await httpClient().post("/v1/education/auth/st/login", data);
}

export const logout = async () => {
    window.sessionStorage.removeItem('authToken');
    return Promise.resolve();
}

export const signup = async (data) => {
    return await  httpClient().post("/v1/education/auth/signup", data);
}

export const resetPassword = async (data) => {
    return await  httpClient().put("/v1/education/auth/rstpass/" + data.userId, data);
}

export const toggleActivation = async (id, data) => {
    return await  httpClient().put("/v1/education/auth/toggle/" + id, data);
}

export const isLoggedInSafeToRemove = (token = window.sessionStorage.getItem("authToken")) => {
    return token
        ? Promise.resolve()
        : Promise.reject();
}