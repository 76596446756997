import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import * as casestudyService from "../../services/CasestudyService";
import Notification from "../../components/mui/Notification";
import { CircularProgress, Container, Grid } from "@mui/material";
import CheckoutForm from "./CheckoutForm";
import { useLocation } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutPage = () => {
  const { state } = useLocation();
  const amount = state.amount;
  const amountInCents = amount * 100;
  const currency = state.currency;
  const caseStudyId = state.caseStudyId;
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    casestudyService
      .getClientSecret({
        amount: amountInCents,
        currency,
        caseStudyId,
      })
      .then((response) => {
        setClientSecret(response.data.value);
      })
      .catch((error) => {
        console.error(`Error: ${error.message}`);
        setNotify({
          isOpen: true,
          message: "Something went wrong. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Grid container justifyContent="center">
        {loading || !clientSecret ? (
          <CircularProgress />
        ) : (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              clientSecret={clientSecret}
              amount={amount}
              currency={currency}
            />
          </Elements>
        )}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
};

export default CheckoutPage;
