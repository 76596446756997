import React, { useState, useEffect } from "react";
import { Grid, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container, Paper } from "@mui/material";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useParams } from "react-router-dom";
import * as authService from "../../services/AuthService";
import Notification from "../../components/mui/Notification";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    width: "100%",
    margin: "20px auto",
  },
  btn: {
    margin: "8px 0",
  },
}));

export default function AccountVerified(props) {
  const classes = useStyles();
  const { userId } = useParams();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    authService.toggleActivation(userId, { enabled: true }).catch((e) => {
      console.log(e);
      setNotify({
        isOpen: true,
        message: "Could not connect to server. Please try again later.",
        type: "error",
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={10} className={classes.paper}>
        <Grid container justifyContent="center">
          <Grid item align="center" lg={12} xs={10} sm={10} md={10}>
            <Avatar sx={{ bgcolor: "#1bbd7e" }}>
              <LockOpenOutlinedIcon />
            </Avatar>
            <h1>Successfully Verified!</h1>
          </Grid>
          <Grid item align="center" xs={12} sx={{ mt: "1em", mb: "2em" }}>
            <h3>Please log in to start your journey.</h3>
          </Grid>
        </Grid>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
