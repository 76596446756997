import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import ActionCards from "./components/ActionCards";
import { IconButton, Collapse } from "@mui/material";
import { Link as Scroll } from "react-scroll";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100vh',
    // backgroundImage: `url(${'/resources/upload.png'})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
  },
  colorText: {
    color: "orange",
  },
  container: {
    textAlign: "center",
  },
  title: {
    color: "#000",
    fontSize: "2.5rem",
  },
  goDown: {
    color: "#000",
    fontSize: "4rem",
  },
}));

export default function Main() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <Collapse
        in={checked}
        {...(checked ? { timeout: 1000 } : {})}
        collapsedheight={50}
      >
        <div className={classes.container}>
          <h1 className={classes.title}>
            <span className={classes.colorText}>
              Accelerate Your Learning Journey
            </span>{" "}
            <br />
            in a Flash with Our Case Study Lab
          </h1>
          <Scroll to="action-cards" smooth={true}>
            <IconButton>
              <ExpandMoreIcon className={classes.goDown} />
            </IconButton>
          </Scroll>
        </div>
      </Collapse>
      <ActionCards />
    </div>
  );
}
